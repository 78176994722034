import React, { CSSProperties } from 'react';

import { DeleteOutlined, KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { IconButton, Paper, Stack, Tooltip } from '@mui/material';

import { TEditorBlock } from '../../../editor/core';
import { resetDocument, useDocument } from '../../../editor/EditorContext';
import { ColumnsContainerProps } from '../../ColumnsContainer/ColumnsContainerPropsSchema';

const STYLE: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: -52,
  borderRadius: 64,
};

type Props = {
  blockId: string;
};
export default function TuneMenu({ blockId }: Props) {
  const document = useDocument();
  
  const siblings = (newSiblings) => {
      const nDocument: typeof document = { ...document };
	  for (const [id, b] of Object.entries(nDocument)) {
		  const block = b as TEditorBlock;
		  if (id === blockId) {
			continue;
		  }
		  switch (block.type) {
			case 'EmailLayout':
			  if (block.data.childrenIds && block.data.childrenIds.includes(blockId)) {
				  if (newSiblings) {
					  block.data.childrenIds = newSiblings;
					  return nDocument;
				  }
				  return block.data.childrenIds;
			  }
			  break;
			case 'Container':
			  if (block.data.props.childrenIds && block.data.props.childrenIds.includes(blockId)) {
				  if (newSiblings) {
					  block.data.props.childrenIds = newSiblings;
					  return nDocument;
				  }
				  return block.data.props.childrenIds;
			  }
			  break;
			case 'ColumnsContainer':
			  if (block.data.props.columns) {
				  for (var i = 0; i < block.data.props.columns.length; ++i) {
					  if (block.data.props.columns[i].childrenIds && block.data.props.columns[i].childrenIds.includes(blockId)) {
						  if (newSiblings) {
							  block.data.props.columns[i].childrenIds = newSiblings;
							  return nDocument;
						  }
						  return block.data.props.columns[i].childrenIds;
					  }
				  }
			  }
			  break;
		  }
		}
	return [];
  }

  const handleDeleteClick = () => {
    const filterChildrenIds = (childrenIds: string[] | null | undefined) => {
      if (!childrenIds) {
        return childrenIds;
      }
      return childrenIds.filter((f) => f !== blockId);
    };
	
	var blockSiblings = siblings();
	
	if (blockSiblings) {
		var nDocument = siblings( filterChildrenIds( blockSiblings ) );
		
		delete nDocument[blockId];
		resetDocument(nDocument);
	}
  };
  
  
  const moveBlock = (up) => {
    var blockSiblings = siblings();
	
	if (blockSiblings) {
		var currentIndex = blockSiblings.indexOf(blockId);
		if (currentIndex === (up ? 0 : blockSiblings.length - 1)) {
			return;
		}
		
		var newIndex = up ? currentIndex - 1 : currentIndex + 1;
		
		blockSiblings.splice(currentIndex, 1);
		blockSiblings.splice(newIndex, 0, blockId);
		
		resetDocument(siblings(blockSiblings));
	}
  };

  var blockSiblings = siblings();

  return (
    <Paper style={STYLE} onClick={(ev) => ev.stopPropagation()}>
      <Stack>
        <Tooltip title="Move Up" placement="left-start">
		  <IconButton onClick={() => moveBlock(true)} disabled={!blockSiblings.indexOf(blockId)} sx={{ color: 'text.primary' }}>
			<KeyboardArrowUpOutlined fontSize="small" />
		  </IconButton>
        </Tooltip>
      </Stack>
      <Stack>
        <Tooltip title="Move Down" placement="left-start">
		  <IconButton onClick={() => moveBlock()} disabled={blockSiblings.indexOf(blockId) === blockSiblings.length - 1} sx={{ color: 'text.primary' }}>
			<KeyboardArrowDownOutlined fontSize="small" />
		  </IconButton>
        </Tooltip>
      </Stack>
      <Stack>
        <Tooltip title="Delete" placement="left-start">
          <IconButton onClick={handleDeleteClick} sx={{ color: 'text.primary' }}>
            <DeleteOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Paper>
  );
}
