import React, {useState} from "react";

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { KeyboardArrowDown } from '@mui/icons-material';

export default function MergeFields() {
	
    const [isOpen, setIsOpen] = useState(false);
  
	return (
		<Accordion className="hiveify-merge-fields">
			<AccordionSummary expandIcon={<KeyboardArrowDown />}>Merge Fields</AccordionSummary>
			<AccordionDetails>
				<dl>
					<dt>{"{{email}}"}</dt>
					<dd>The email address of the recipient.</dd>
					<dt>{"{{first_name}}"}</dt>
					<dd>The first name of the recipient.</dd>
					<dt>{"{{last_name}}"}</dt>
					<dd>The last name of the recipient.</dd>
					<dt>{"{{unsubscribe_url}}"}</dt>
					<dd>The URL where the recipient can unsubscribe from the campaign.</dd>
					<dt>{"{{subscribe_url}}"}</dt>
					<dd>The URL where the recipient can subscribe to the campaign, primarily relevant for opt-in emails.</dd>
					<dt>{"{{webview_url}}"}</dt>
					<dd>The URL to view the email in the browser.</dd>
				</dl>
			</AccordionDetails>
		</Accordion>
	);
}