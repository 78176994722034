/*!
*
* wpz-email-builder
*
* When compiled (indicated by the filename main.js): this file includes material
* from the material-design-icons project; this file has been modified by
* Jonathan Hall; licensing and copyright information is available at ../LICENSE
*
*
*/

import React from 'react';
import ReactDOM from 'react-dom/client';

import { CssBaseline, ThemeProvider } from '@mui/material';

import App from './App';
import theme from './theme';

// based on src/App/SamplesDrawer/SidebarButton.tsx
import { resetDocument } from './documents/editor/EditorContext.tsx';


window.wpzEmailBuilderOpen = function() {
	var target = window.document.getElementById('wpz-email-builder');
	target.className = 'active';
	window.wpzEmailBuilderRoot = ReactDOM.createRoot(target);
	window.wpzEmailBuilderRoot.render(
	  <React.StrictMode>
		<ThemeProvider theme={theme}>
		  <CssBaseline />
		  <App />
		</ThemeProvider>
	  </React.StrictMode>
	);
	
	var field = window.document.getElementById('wpz-email-builder-content-field');
	if (field && field.value && field.value.substring(0, 12) === '<!--@wpzsrc:') {
		var srcEnd = field.value.indexOf('-->');
		if (srcEnd !== -1) {
			resetDocument( JSON.parse(decodeURIComponent(atob(field.value.substring(12, srcEnd)))), true );
		}
	}
}

var preview = window.document.getElementById('wpz-email-preview');
var field = window.document.getElementById('wpz-email-builder-content-field');
if (preview && field && field.value) {
	var html = field.value;
	if (html.substring(0, 12) === '<!--@wpzsrc:') {
		var srcEnd = html.indexOf('-->');
		if (srcEnd !== -1) {
			html = html.substring(srcEnd + 3);
		}
	}
	
	 // based on src/App/TemplatePanel/index.tsx
	 preview.src = 'data:text/html;charset=UTF-8,' + encodeURIComponent(html);
}