import { useEffect } from "react";
import { logger } from "@rpldy/shared";
import { useUploadyContext } from "@rpldy/shared-ui";
const getUrl = form => {
  const loc = window.location;
  let url = form.getAttribute("action") || "";
  url = url.replace(/\s/g, "");
  let path;
  switch (true) {
    case url === "":
      url = loc.href;
      break;
    case url.startsWith("/"):
      url = `${loc.protocol}//${loc.host}${url}`;
      break;
    case !/:\/\//.test(url):
      path = loc.pathname.split("/").slice(0, -1).concat("").join("/");
      url = `${loc.protocol}//${loc.host}${path}${url}`;
      break;
  }
  return url;
};
const getDestinationFromInput = input => {
  const form = input.closest("form");
  let destination = {
    filesParamName: input.getAttribute("name"),
    method: undefined,
    url: undefined
  };
  if (form) {
    const method = form.getAttribute("method"),
      url = getUrl(form);
    destination.method = method ? method.toUpperCase() : undefined;
    destination.url = url;
    logger.debugLog(`Uploady.useFileInput: using custom input's parent form url ${destination.url} and method ${destination.method || ""}`);
  }
  return destination;
};
const useFileInput = fileInputRef => {
  const context = useUploadyContext();
  if (fileInputRef) {
    context.setExternalFileInput(fileInputRef);
  }
  useEffect(() => {
    if (fileInputRef?.current && "closest" in fileInputRef.current) {
      const input = fileInputRef.current;
      const uploaderOptions = context.getOptions();
      if (!uploaderOptions.destination || !uploaderOptions.destination.url) {
        const domDestination = getDestinationFromInput(input);
        context.setOptions({
          destination: domDestination
        });
      }
    }
  }, [fileInputRef, context]);
  return !!fileInputRef ? fileInputRef : context.getInternalFileInput();
};
export default useFileInput;