import React, { useState, useEffect } from 'react';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete
} from '@mui/material';

import { resetDocument } from '../../../documents/editor/EditorContext';

//import validateJsonStringValue from './validateJsonStringValue';

type LoadTemplateDialogProps = {
  onClose: () => void;
};
export default function LoadTemplateDialog({ onClose }: ImportJsonDialogProps) {
  const [value, setValue] = useState('');
  const [templateOptions, setTemplateOptions] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const onChange = (dropdown, value) => {
    setValue(value);
    //const { error } = validateJsonStringValue(v);
    //setError(error ?? null);
  };
  
    // Data fetching based on https://raw.githubusercontent.com/The-Marcy-Lab-School/react-useEffect-fetch-example/refs/heads/main/src/App.jsx
  
	// Fetching helper function
	const fetchData = async (url) => {
	  try {
		const response = await fetch(url);
		const data = await response.json();
		return data;
	  } catch (error) {
		console.log(error);
		return null;
	  }
	}

  const onDropdownOpen = () => {
	  setIsDropdownOpen(true);
  
	
	const doFetch = async () => { 
	  var url = wpzEmailBuilderConfig.apiRoot + '/templates';
	  var templates = [];
	  do {
		const responseData = await fetchData(url);
		for (var i = 0; i < responseData.data.length ?? 0; ++i) {
			templates.push({
				id: responseData.data[i].id,
				name: responseData.data[i].name
			});
		}
		url = responseData.links.next ?? null;
	  } while (url);
	  setTemplateOptions(templates);
	}
	doFetch(); // and we just call the function immediately
  }
  
  const onDropdownClose = () => {
	  setIsDropdownOpen(false);
  }
  
  

  // Autocomplete based on https://raw.githubusercontent.com/mui/material-ui/refs/heads/master/docs/data/material/components/autocomplete/Asynchronous.tsx
  
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Load Template</DialogTitle>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
		  setIsImporting(true);
		  
		  const doImport = async () => { 
				var url = wpzEmailBuilderConfig.apiRoot + '/templates/' + value.id;
				const responseData = await fetchData(url);
			
				var content = responseData.data.content ?? '';
				if (content.substring(0, 12) === '<!--@wpzsrc:') {
					var srcEnd = content.indexOf('-->');
					if (srcEnd !== -1) {
						try {
							resetDocument( JSON.parse(decodeURIComponent(atob(content.substring(12, srcEnd)))), true );
							onClose();
						} catch (ex) { }
					}
				}
				
				setIsImporting(false);
			}
			doImport(); // and we just call the function immediately
		  
        }}
      >
        <DialogContent>
		  <Autocomplete
			  sx={{ width: 300 }}
			  open={isDropdownOpen}
			  onOpen={onDropdownOpen}
			  onClose={onDropdownClose}
			  onChange={onChange}
			  isOptionEqualToValue={(option, value) => option.id === value.id}
			  getOptionLabel={(option) => option.name}
			  options={templateOptions ?? []}
			  loading={templateOptions === null}
			  renderInput={(params) => (
				<TextField
				  {...params}
				  label="Template"
				  slotProps={{
					input: {
					  ...params.InputProps,
					  endAdornment: (
						<React.Fragment>
						  {templateOptions === null ? <CircularProgress color="inherit" size={20} /> : null}
						  {params.InputProps.endAdornment}
						</React.Fragment>
					  ),
					},
				  }}
				/>
			)}
		/>
		
		
          <Typography color="text.secondary" paragraph>
            Importing a template will replace your email's current content.
          </Typography>
		  
		  
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" disabled={!value || isImporting}>
			{isImporting ?  <CircularProgress color="inherit" size={20} sx={{marginRight:'5px'}} /> : null}
            Import
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
