import React from 'react';

import { MonitorOutlined, PhoneIphoneOutlined, CloseOutlined, UndoOutlined, RedoOutlined } from '@mui/icons-material';
import { Box, Stack, SxProps, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from '@mui/material';
import { Reader } from '@usewaypoint/email-builder';

import EditorBlock from '../../documents/editor/EditorBlock';
import {
  setSelectedScreenSize,
  useDocument,
  useSelectedMainTab,
  useSelectedScreenSize,
  documentUndo,
  documentRedo,
  documentCanUndo,
  documentCanRedo
} from '../../documents/editor/EditorContext';
import ToggleInspectorPanelButton from '../InspectorDrawer/ToggleInspectorPanelButton';

import LoadTemplate from './LoadTemplate';
import DownloadJson from './DownloadJson';
import ImportJson from './ImportJson';
import MainTabsGroup from './MainTabsGroup';
import ShareButton from './ShareButton';

import { renderToStaticMarkup } from '@usewaypoint/email-builder';

export default function TemplatePanel() {
  const document = useDocument();
  const selectedMainTab = useSelectedMainTab();
  const selectedScreenSize = useSelectedScreenSize();

  let mainBoxSx: SxProps = {
    height: '100%',
  };
  if (selectedScreenSize === 'mobile') {
    mainBoxSx = {
      ...mainBoxSx,
      margin: '32px auto',
      width: 370,
      height: 800,
      boxShadow:
        'rgba(33, 36, 67, 0.04) 0px 10px 20px, rgba(33, 36, 67, 0.04) 0px 2px 6px, rgba(33, 36, 67, 0.04) 0px 0px 1px',
    };
  }

  const handleScreenSizeChange = (_: unknown, value: unknown) => {
    switch (value) {
      case 'mobile':
      case 'desktop':
        setSelectedScreenSize(value);
        return;
      default:
        setSelectedScreenSize('desktop');
    }
  };

  const renderMainPanel = () => {
    switch (selectedMainTab) {
      case 'editor':
        return (
          <Box sx={mainBoxSx}>
            <EditorBlock id="root" />
          </Box>
        );
      case 'preview':
        return (
          <Box sx={mainBoxSx}>
            <Reader document={document} rootBlockId="root" />
          </Box>
        );
    }
  };
  
  const closeEditor = () => {
	  var field = window.document.getElementById('wpz-email-builder-content-field');
	  // based on src/App/TemplatePanel/HtmlPanel.tsx
	  var html = renderToStaticMarkup(document, { rootBlockId: 'root' });
	  
	  if (field) {
		  field.value = '<!--@wpzsrc:' + btoa(encodeURIComponent(JSON.stringify(document))) + '-->' + html;
	  }
	  
	  var preview = window.document.getElementById('wpz-email-preview');
	  if (preview) {
		  preview.src = 'data:text/html;charset=UTF-8,' + encodeURIComponent(html);
	  }
	  
	  if (window.wpzEmailBuilderRoot) {
		  window.wpzEmailBuilderRoot.unmount();
		  delete window.wpzEmailBuilderRoot;
	  }
	  
	  var target = window.document.getElementById('wpz-email-builder');
	  if (target) {
	      target.className = '';
	  }
  };

  return (
    <>
      <Stack
        sx={{
          height: 49,
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          zIndex: 'appBar',
          px: 1,
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack px={2} direction="row" gap={2} width="100%" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2}>
            <MainTabsGroup />
          </Stack>
          <Stack direction="row" spacing={2}>
		    <IconButton onClick={closeEditor}>
               <Tooltip title="Close editor">
			     <CloseOutlined fontSize="small" />
			   </Tooltip>
			</IconButton>
		    <IconButton onClick={documentUndo} disabled={!documentCanUndo()}>
               <Tooltip title="Undo">
			     <UndoOutlined fontSize="small" />
			   </Tooltip>
			</IconButton>
		    <IconButton onClick={documentRedo} disabled={!documentCanRedo()}>
               <Tooltip title="Redo">
			     <RedoOutlined fontSize="small" />
			   </Tooltip>
			</IconButton>
            <LoadTemplate />
            <DownloadJson />
            <ImportJson />
            <ToggleButtonGroup value={selectedScreenSize} exclusive size="small" onChange={handleScreenSizeChange}>
              <ToggleButton value="desktop">
                <Tooltip title="Desktop view">
                  <MonitorOutlined fontSize="small" />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="mobile">
                <Tooltip title="Mobile view">
                  <PhoneIphoneOutlined fontSize="small" />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <ShareButton />
          </Stack>
        </Stack>
        <ToggleInspectorPanelButton />
      </Stack>
      <Box sx={{ height: 'calc(100vh - 49px)', overflow: 'auto', minWidth: 370 }}>{renderMainPanel()}</Box>
    </>
  );
}
