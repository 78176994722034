import React, { useState } from 'react';
import Uploady, { useItemStartListener, useItemFinishListener } from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress
} from '@mui/material';

import {
  Delete, FileUpload
} from '@mui/icons-material';

type ImageSelectDialogProps = {
  onClose: () => void;
  onChange: (selection) => void;
};
export default function ImageSelectDialog({ onClose, onChange }: ImageSelectDialogProps) {
  const [selection, setSelection] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState(null);
  
	// based on https://raw.githubusercontent.com/rpldy/react-uploady/refs/tags/v1.8.3/README.md
	const ImageUploadButton = asUploadButton((props) => {
		if (isUploading) {
			props.disabled = true;
		}
		return <Button {...props}>{isUploading ? <CircularProgress /> : <FileUpload />} Upload Image</Button>;
	});
	
	const LogProgress = () => {
		useItemStartListener(() => {
			setIsUploading(true);
		});
		useItemFinishListener(() => {
			setIsUploading(false);
			doFetch();
		});

		return null;
	}
	
	
    // Data fetching based on https://raw.githubusercontent.com/The-Marcy-Lab-School/react-useEffect-fetch-example/refs/heads/main/src/App.jsx
  
	// Fetching helper function
	const fetchData = async (url, method) => {
	  try {
		const response = await fetch(url, {method: method ?? 'GET'});
		const data = await response.json();
		return data;
	  } catch (error) {
		console.log(error);
		return null;
	  }
	}
	
	const doFetch = async () => { 
	  var url = wpzEmailBuilderConfig.apiRoot + '/images';
	  var images = [];
	  do {
		const responseData = await fetchData(url);
		for (var i = 0; i < responseData.data.length ?? 0; ++i) {
			images.push({
				id: responseData.data[i].id,
				name: responseData.data[i].name,
				url: responseData.data[i].url
			});
		}
		url = responseData.links.next ?? null;
	  } while (url);
	  setImages(images);
	}
	
	if (images === null) {
		doFetch();
	}
	
	const deleteImage = async (id) => {
		if (confirm('Are you sure?')) {
			await fetchData(wpzEmailBuilderConfig.apiRoot + '/images/' + id + '?_token=' + wpzEmailBuilderConfig.token, 'DELETE');
			doFetch();
			return true;
		}
		return false;
	}
	
	// image list based on https://raw.githubusercontent.com/mui/material-ui/refs/tags/v6.1.1/docs/data/material/components/image-list/StandardImageList.tsx and https://raw.githubusercontent.com/mui/material-ui/refs/tags/v6.1.1/docs/data/material/components/image-list/TitlebarImageList.tsx
  
  return (
    <Dialog open onClose={onClose} fullWidth={true} maxWidth='lg'>
		<DialogTitle>Select or Upload Image</DialogTitle>
		<DialogContent>
			{images === null
				? <CircularProgress />
				: <ImageList cols={3} rowHeight={120} className="wpz-email-builder-images">
				  {images.map((item) => (
					<ImageListItem key={item.url} className={item.url === selection ? 'wpz-email-builder-selected' : null}>
					  <a href="#" onClick={() => {setSelection(item.url); return false;}}>
						  <img
							src={item.url}
							alt={item.name}
							loading="lazy"
						  />
					   </a>
					  <ImageListItemBar
						title={item.name}
						position="below"
						actionIcon={
						  <IconButton
							aria-label={`Delete image ${item.name}`}
							onClick={() => {if (deleteImage(item.id) && selection == item.url) setSelection('');}}
						  >
							<Delete />
						  </IconButton>
						}
					  />
					</ImageListItem>
				  ))}
				</ImageList>
			}
		</DialogContent>
		<DialogActions>
			<Uploady
				destination={{ url: wpzEmailBuilderConfig.apiRoot + '/images', params: {_token: wpzEmailBuilderConfig.token} }}>
				<LogProgress/>   
				<ImageUploadButton/>
			</Uploady>
		  <Button type="button" onClick={onClose}>
			Cancel
		  </Button>
		  <Button variant="contained" type="submit" disabled={selection === ''} onClick={() => {onChange(selection); onClose();}}>
			Select
		  </Button>
		</DialogActions>
    </Dialog>
  );
}
