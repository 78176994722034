// based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/examples/react-rich/src/plugins/ToolbarPlugin.tsx
import React from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister, $getNearestNodeOfType} from '@lexical/utils';
import {$isListItemNode, $isListNode, ListItemNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND} from '@lexical/list';
import {TOGGLE_LINK_COMMAND, $isLinkNode, LinkNode} from '@lexical/link';
import {
  $getSelection,
  $isRangeSelection,
  $isLeafNode,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {
  $patchStyleText,
  $getSelectionStyleValueForProperty
} from '@lexical/selection';
import {useCallback, useEffect, useRef, useState} from 'react';

import { FormatBoldOutlined, FormatItalicOutlined, FormatUnderlinedOutlined, FormatStrikethroughOutlined, FormatColorTextOutlined, FormatAlignLeftOutlined, FormatAlignCenterOutlined, FormatAlignRightOutlined, FormatAlignJustifyOutlined, LinkOffOutlined, LinkOutlined, FormatListBulletedOutlined, FormatListNumberedOutlined } from '@mui/icons-material';

import { Menu, Button } from '@mui/material';
import Picker from './helpers/inputs/ColorInput/Picker'

const LowPriority = 1;

function Divider() {
  return <div className="divider" />;
}

export default function ToolbarPlugin(props) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [openColorPickerButton, setOpenColorPickerButton] = useState(null);
  const [isInLink, setIsInLink] = useState(false);
  const [isInOList, setIsInOList] = useState(false);
  const [isInUList, setIsInUList] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [textColor, setTextColor] = useState('');
  
  // based on https://github.com/facebook/lexical/blob/v0.19.1-nightly.20241101.0/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx
  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      editor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? {tag: 'historic'} : {},
      );
    },
    [editor],
  );

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
	  setIsInOList( $isRangeSelectionInList(selection, 'number') );
	  setIsInUList( $isRangeSelectionInList(selection, 'bullet') );
	  try {
		setIsInLink( $isRangeSelectionInLink(selection) );
	  } catch (ex) { }
	  
	  // based on https://github.com/facebook/lexical/blob/v0.19.1-nightly.20241101.0/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx
	  setTextColor( $getSelectionStyleValueForProperty(selection, 'color', '') );
    }
  }, []);
  
  const $isRangeSelectionInList = (selection, listType) => {
	  // based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/packages/lexical-list/src/formatList.ts
      const nodes = selection.getNodes();
      const anchorNode = selection.anchor.getNode();
	  
      if ( $isListItemNode(anchorNode) && (nodes.length === 0 || (nodes.length === 1 && anchorNode.is(nodes[0]) && anchorNode.getChildrenSize() === 0)) ) {
		  var parentNode = anchorNode.getParent();
		  if ($isListNode(parentNode) && parentNode.getListType() === listType) {
			return true;
		  }
      }
	  
		for (let i = 0; i < nodes.length; i++) {
		  if ($isLeafNode(nodes[i])) {
			  var listItemNode = $getNearestNodeOfType(nodes[i], ListItemNode);
			  if (listItemNode !== null) {
				  var parentNode = listItemNode.getParent();
				  if ($isListNode(parentNode) && parentNode.getListType() === listType) {
					return true;
				  }
			  }
		  }
		}
		
		return false;
  };
  
  
  const $isRangeSelectionInLink = (selection) => {
	  // based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/packages/lexical-link/src/index.ts
	  const nodes = selection.extract();
	  
	  if (!nodes.length) {
		  return false;
	  }
	  
	  var parentNode = null;
	  for (var i = 0; i < nodes.length; ++i) {
		  var nodeParent = nodes[i].getParent();
		  if ( !nodeParent || (parentNode !== null && !parentNode.is(nodeParent)) || !$isLinkNode(nodeParent) ) {
			  return false;
		  }
		  if (parentNode === null) {
			  parentNode = nodeParent;
		  }
	  }
	  
	  return true;
  };
  
  const toggleColorPicker = (clickEvent) => {
	  setOpenColorPickerButton(openColorPickerButton ? null : clickEvent.currentTarget);
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      )
    );
  }, [editor, $updateToolbar]);

  // color dropdown based on wpz-email-builder\src\App\InspectorDrawer\ConfigurationPanel\input-panels\helpers\inputs\ColorInput\BaseColorInput.tsx
	   
  return (
  
    <div className="toolbar" ref={toolbarRef}>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        aria-label="Format Bold">
        <FormatBoldOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        aria-label="Format Italics">
        <FormatItalicOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        aria-label="Format Underline">
        <FormatUnderlinedOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
        className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
        aria-label="Format Strikethrough">
        <FormatStrikethroughOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={toggleColorPicker}
        className={'toolbar-item spaced'}
        aria-label="Format Text Color">
        <FormatColorTextOutlined fontSize="small" />
      </button>
      <Menu
        anchorEl={openColorPickerButton}
        open={Boolean(openColorPickerButton)}
        onClose={() => setOpenColorPickerButton(null)}
        MenuListProps={{
          //sx: { height: 'auto', padding: 0 },
        }}
      >
        <Picker
          value={textColor}
          onChange={(v) => {
            applyStyleText({'color': v});
          }}
        />
		<Button onClick={() => applyStyleText({'color': null})}>Clear Text Color</Button>
      </Menu>
      <Divider />
      <button type="button"
        onClick={() => {
		  var url = isInLink ? null : prompt('Enter a URL to link to:');
		  if (!url) {
			  url = null;
		  }
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, {url: url, target: '_blank'});
		  
        }}
        className={"toolbar-item spaced" + (isInLink ? ' active' : '')}
        aria-label={isInLink ? 'Remove link' : 'Add link'}>
		{isInLink ? <LinkOffOutlined fontSize="small" /> : <LinkOutlined fontSize="small" />}
      </button>
      <Divider />
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(isInUList ? REMOVE_LIST_COMMAND : INSERT_UNORDERED_LIST_COMMAND, undefined);
        }}
        className={"toolbar-item spaced" + (isInUList ? ' active' : '')}
        aria-label={isInUList ? 'Remove bullet list' : 'Add bullet list'}>
        <FormatListBulletedOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(isInOList ? REMOVE_LIST_COMMAND : INSERT_ORDERED_LIST_COMMAND, undefined);
        }}
        className={"toolbar-item spaced" + (isInOList ? ' active' : '')}
        aria-label={isInUList ? 'Remove number list' : 'Add number list'}>
        <FormatListNumberedOutlined fontSize="small" />
      </button>
      <Divider />
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className="toolbar-item spaced"
        aria-label="Left Align">
        <FormatAlignLeftOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className="toolbar-item spaced"
        aria-label="Center Align">
        <FormatAlignCenterOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className="toolbar-item spaced"
        aria-label="Right Align">
        <FormatAlignRightOutlined fontSize="small" />
      </button>
      <button type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className="toolbar-item"
        aria-label="Justify Align">
        <FormatAlignJustifyOutlined fontSize="small" />
      </button>{' '}
    </div>
  );
}
