import React, { useState } from 'react';

import { LibraryBooksOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import LoadTemplateDialog from './LoadTemplateDialog';

export default function LoadTemplate() {
  const [open, setOpen] = useState(false);

  let dialog = null;
  if (open) {
    dialog = <LoadTemplateDialog onClose={() => setOpen(false)} />;
  }

  return (
    <>
      <Tooltip title="Load Template">
        <IconButton onClick={() => setOpen(true)}>
          <LibraryBooksOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
      {dialog}
    </>
  );
}
