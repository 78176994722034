import { create } from 'zustand';

import getConfiguration from '../../getConfiguration';

import { TEditorConfiguration } from './core';

type TValue = {
  document: TEditorConfiguration;

  selectedBlockId: string | null;
  selectedSidebarTab: 'block-configuration' | 'styles';
  selectedMainTab: 'editor' | 'preview' | 'json' | 'html';
  selectedScreenSize: 'desktop' | 'mobile';

  inspectorDrawerOpen: boolean;
};

const editorStateStore = create<TValue>(() => ({
  document: getConfiguration(window.location.hash),
  selectedBlockId: null,
  selectedSidebarTab: 'styles',
  selectedMainTab: 'editor',
  selectedScreenSize: 'desktop',

  inspectorDrawerOpen: true
}));

const editorDocumentHistory: TEditorConfiguration[] = [];
const editorDocumentFuture: TEditorConfiguration[] = [];
let isDocumentHistoryDeferred: boolean = false;
let isDocumentHistoryDeferralActive: int = 0;

export function useDocument() {
  return editorStateStore((s) => s.document);
}

export function useSelectedBlockId() {
  return editorStateStore((s) => s.selectedBlockId);
}

export function useSelectedScreenSize() {
  return editorStateStore((s) => s.selectedScreenSize);
}

export function useSelectedMainTab() {
  return editorStateStore((s) => s.selectedMainTab);
}

export function setSelectedMainTab(selectedMainTab: TValue['selectedMainTab']) {
  return editorStateStore.setState({ selectedMainTab });
}

export function useSelectedSidebarTab() {
  return editorStateStore((s) => s.selectedSidebarTab);
}

export function useInspectorDrawerOpen() {
  return editorStateStore((s) => s.inspectorDrawerOpen);
}

export function setSelectedBlockId(selectedBlockId: TValue['selectedBlockId']) {
  const selectedSidebarTab = selectedBlockId === null ? 'styles' : 'block-configuration';
  const options: Partial<TValue> = {};
  if (selectedBlockId !== null) {
    options.inspectorDrawerOpen = true;
  }
  return editorStateStore.setState({
    selectedBlockId,
    selectedSidebarTab,
    ...options,
  });
}

export function setSidebarTab(selectedSidebarTab: TValue['selectedSidebarTab']) {
  return editorStateStore.setState({ selectedSidebarTab });
}

export function resetDocument(document: TValue['document'], clearUndoHistory: boolean = false) {
  editorDocumentFuture.splice(0);
  
  if (isDocumentHistoryDeferralActive) {
	  clearTimeout(isDocumentHistoryDeferralActive);
	  isDocumentHistoryDeferralActive = 0;
  }
  
  if (clearUndoHistory) {
	   editorDocumentHistory.splice(0);
  } else {
	  const originalDocument = editorStateStore.getState().document;
      maybeAddDocumentHistoryEntry(originalDocument);
  }
	
  return editorStateStore.setState({
    document,
    selectedSidebarTab: 'styles',
    selectedBlockId: null,
  });
}

export function setDocument(document: TValue['document']) {
  editorDocumentFuture.splice(0);
  
  const originalDocument = editorStateStore.getState().document;
  maybeAddDocumentHistoryEntry(originalDocument);
  
  return editorStateStore.setState({
    document: {
      ...originalDocument,
      ...document,
    },
  });
}

export function maybeAddDocumentHistoryEntry(entry: TValue['document']) {
  if (isDocumentHistoryDeferred) {
	  if (isDocumentHistoryDeferralActive) {
		  return;
	  } else {
		  isDocumentHistoryDeferralActive = setTimeout(function() {
			  isDocumentHistoryDeferralActive = 0;
		  }, 5000);
	  }
  } else if (isDocumentHistoryDeferralActive) {
	  clearTimeout(isDocumentHistoryDeferralActive);
	  isDocumentHistoryDeferralActive = 0;
  }
  
  editorDocumentHistory.push(entry);
}

export function documentUndo() {
  if (documentCanUndo()) {
	  if (isDocumentHistoryDeferralActive) {
		  clearTimeout(isDocumentHistoryDeferralActive);
		  isDocumentHistoryDeferralActive = 0;
	  }
  
	  const originalDocument = editorStateStore.getState().document;
	  if (editorDocumentFuture.length === 25) {
		  editorDocumentFuture.shift();
	  }
	  editorDocumentFuture.push(originalDocument);
	  
	  return editorStateStore.setState({
		document: editorDocumentHistory.pop()
	  });
  }
}

export function documentRedo() {
  if (documentCanRedo()) {
	  if (isDocumentHistoryDeferralActive) {
		  clearTimeout(isDocumentHistoryDeferralActive);
		  isDocumentHistoryDeferralActive = 0;
	  }
	  
	  const originalDocument = editorStateStore.getState().document;
	  if (editorDocumentHistory.length === 25) {
		  editorDocumentHistory.shift();
	  }
	  editorDocumentHistory.push(originalDocument);
	  
	  return editorStateStore.setState({
		document: editorDocumentFuture.pop()
	  });
  }
}

export function documentDeferHistory() {
	isDocumentHistoryDeferred = true;
}

export function documentCanUndo() {
	return editorDocumentHistory.length > 0;
}

export function documentCanRedo() {
	return editorDocumentFuture.length > 0;
}

export function toggleInspectorDrawerOpen() {
  const inspectorDrawerOpen = !editorStateStore.getState().inspectorDrawerOpen;
  return editorStateStore.setState({ inspectorDrawerOpen });
}

export function setSelectedScreenSize(selectedScreenSize: TValue['selectedScreenSize']) {
  return editorStateStore.setState({ selectedScreenSize });
}
